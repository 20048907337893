/* eslint-disable */
body {
  --inputWidth: 756px;
  --valueWidth: 70%;
  --hashWidth: 955px;
  --pFontSize: 16px;
  --pOneFontSize: 18px;
  --codeInput: 400px;
  --codenums: 340px;
  --innerWidth: 1440px;
  --bannerFontSizeH3: 28px;
  --bannerFontSizeH2: 45px;
  --bannerFontSizeP: 18px;
  --bannerMarginBottomH2: 10px;
  --L1: 210px;
  --L2: 250px;
  --L3: 340px;
  --Lright: 30px;
  --bodyWidth: 1440px;
}

@media screen and (max-width: 1440px) {
  body {
    --pOneFontSize: 14px;
    --pFontSize: 12px;
    --valueWidth: 65%;
    --inputWidth: 540px;
    --hashWidth: 739px;
    --codeInput: 224px;
    --codenums: 300px;
    --innerWidth: 1200px;
    --bannerMarginBottomH2: 0px;
    --bannerFontSizeH3: 24px;
    --bannerFontSizeP: 17px;
    --bannerFontSizeH2: 38px;
    --L1: 170px;
    --L2: 210px;
    --L3: 250px;
    --Lright: 15px;
    --bodyWidth: 1200px;
  }
}

body {
  min-width: 1200px;
}

.t-layout {
  min-height: 100vh;
  background-color: rgb(235, 237, 241);
}

.t-layout--content {
  padding: 0;
}

.clear-gap {
  width: 100%;
  height: 480px;
  background-color: #f1f3f4;
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 64px;

  .tpm-prod-hero__bg-inner {
    background-image: url('../../assets/img/banner.jpg');
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: auto 715px;
    background-repeat: no-repeat;
    background-position: top;
  }

  .tpm-prod-hero__inner {
    z-index: 2;
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding-top: 62px;
    max-width: 1200px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flexbox;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;

    .tpm-prod-hero__content {
      position: relative;
      z-index: 1;
      color: #3d485d;
      margin-top: -6px;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: none;

      .tpm-prod-hero__header {
        margin-bottom: 12px;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;

        .tpm-prod-hero__tit {
          font-size: 40px;
          line-height: 52px;
          font-weight: 500;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .tpm-prod-hero__desc {
        font-size: 16px;
        line-height: 28px;
        max-width: 700px;
      }
    }
  }
}

.back_two_img {
  position: relative;
  height: 850px;

  .bot_right {
    position: absolute;
    width: 500px;
    height: 600px;
    z-index: 0;
  }

  .bot_right {
    bottom: -130px;
    left: -150px;
  }

  .evi_inner {
    width: var(--bodyWidth);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform: translateX(-50%);

    .verification,
    .verification2 {
      height: 316px;
      width: 100%;
      margin-bottom: 60px;
      display: flexbox;
      display: box;
      display: flex;
      flex-wrap: wrap;
      flex-wrap: wrap;
      background-color: #fff;
    }

    .verification2 {
      position: relative;
      height: 381px;

      .banner_left {
        width: 416px;
        height: 100%;
        border: 8px solid #f6f6f6;
        border-radius: 5px;
        box-sizing: border-box;
        box-sizing: border-box;
        background-color: #fff;

        img {
          display: block;
          margin: 50px auto;
        }
      }

      .input_value {
        position: relative;
        padding-left: 40px;
        box-sizing: border-box;
        box-sizing: border-box;
        width: var(--valueWidth);
        height: 100%;

        .tit-wrap {
          display: box;
          display: flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
        }

        h2 {
          height: 42px;
          font-family: PingFangSC;
          font-weight: 600;
          font-size: 30px;
          color: #000;
          letter-spacing: 0;
          margin-bottom: 1px;
          margin-top: 0;
        }

        p {
          margin-top: 0;
          height: 22px;
          font-family: PingFangSC;
          font-weight: 400;
          font-size: 16px;
          color: #999;
          letter-spacing: 0;
          margin-bottom: 13px;

          img {
            position: relative;
            top: 1px;
            left: 1px;
            margin-right: 3px;
          }

          strong {
            position: absolute;
            right: 0px;
            top: 44px;
            font-weight: 400;
            font-size: 14px;
          }
        }

        .upload {
          height: 306px;
          background: rgba(247, 250, 255, .73);
          border: 1px solid #dde3ec;
          border-radius: 4px;
          padding: 16px 20px 16px 16px;
          overflow: hidden;
          display: block;

          .upload_main {
            border: 1px dashed #adadad;
            height: 210px;
            border-radius: 4px;
            display: flexbox;
            display: box;
            display: flex;
            -ms-flex-align: center;
            -webkit-box-align: center;
            align-items: center;
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            background-color: #fff;
            cursor: pointer;
            width: 100%;

            &:hover {
              border: 2px dashed #2468f2;
            }

            .tea-form-upload-drag {
              min-width: 100%;
              min-height: 206px;
            }

            .tea-form-upload-drag .tea-form-upload__inner {
              min-width: 100%;
              min-height: 206px;
              border: 0;
            }

            .upload_uploadfile {
              display: flex;
              flex-direction: column;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              flex-direction: column;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              -ms-flex-pack: center;
              -webkit-box-pack: center;
              justify-content: center;

              & > img {
                width: 3.3333333333vw;
              }

              p {
                color: #666;
                margin-top: 12px;
                font-size: 14px;
              }
            }
          }

          .upload_bottom {
            display: flexbox;
            display: box;
            display: flex;
            -ms-flex-pack: justify;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -ms-flex-align: center;
            -webkit-box-align: center;
            align-items: center;
            cursor: pointer;

            display: flex;

            .upload_but,
            .upload_check {
              flex: 1;
              height: 50px;

              border-radius: 6px;
              margin-top: .8333333333vw;
              display: flexbox;
              display: box;
              display: flex;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              -ms-flex-pack: center;
              -webkit-box-pack: center;
              justify-content: center;

              p {
                font-size: 18px;
                color: #fff;
                margin-left: 10px;
                font-family: PingFangSC;
                margin-bottom: 0;
              }
            }

            .upload_but {
              background-color: #2468f2;

              img {
                width: 20px;
                margin-top: 2px;
              }
            }

            .upload_check {
              margin-left: 20px;
              background-color: #e49c3f;

              img {
                width: 30px;
                position: absolute;
                right: 40px;
              }
            }
          }

          .upload_file_main {
            width: 100%;
            height: 210px;
            box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #adadad;
            border-radius: 4px;
            padding: 12px 15px;
            overflow-y: auto;
            background-color: #fff;
            position: relative;

            .FileSec,
            .FileSec > .FileSec_left {
              display: flexbox;
              display: box;
              display: flex;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              position: relative;
            }

            .FileSec {
              border-bottom: 1px solid #d9d9d9;
              height: 3.125vw;
              width: 100%;
              -ms-flex-pack: justify;
              -webkit-box-pack: justify;
              justify-content: space-between;

              .FileSec_left {
                -ms-flex-pack: start;
                -webkit-box-pack: start;
                justify-content: flex-start;
                margin-left: 10px;

                img {
                  width: 20px;
                  margin-right: 10px;
                }

                p {
                  font-size: 14px;
                  margin-bottom: 0;
                  color: #333;
                  margin-left: 10px;
                }
              }

              .FileSec_right {
                display: flexbox;
                display: box;
                display: flex;
                -ms-flex-pack: end;
                -webkit-box-pack: end;
                justify-content: flex-end;
                -ms-flex-align: center;
                -webkit-box-align: center;
                align-items: center;

                img {
                  width: 13px;
                }

                .close-icon {
                  margin-right: 15px;
                  cursor: pointer;
                }

                .FileSec_right_tab {
                  width: 68px;
                  height: 24px;
                  line-height: 24px;
                  text-align: center;
                  border: 1px solid #237dd5;
                  border-radius: 4px;
                  font-size: 14px;
                  font-family: PingFang;
                  color: #237dd5;
                  margin-right: 20px;
                }
              }
            }

            .error,
            .success {
              margin-bottom: 10px;

              .FileSec {
                border: 0;
              }

              .unpass-tag,
              .pass-tag {
                position: relative;

                p {
                  font-size: 14px;
                  padding: 0 5px;
                  margin-right: 30px;
                  margin-top: 13px;
                }

                .open_icon,
                .close_icon {
                  cursor: pointer;
                  position: absolute;
                  right: 10px;
                  top: 20px;
                  width: 10px;
                }

                .close_icon {
                  transform: rotate(180deg);
                  transform: rotate(180deg);
                }
              }

              .unpass-tag {
                p {
                  color: rgb(224, 19, 19);
                  border: 1px solid rgb(224, 19, 19);
                }
              }

              .pass-tag {
                p {
                  color: #00a15b;
                  border: 1px solid #00a15b;
                }
              }

              .main {
                padding: 0 10px;

                .main_title {
                  display: flexbox;
                  display: box;
                  display: flex;
                  -ms-flex-align: center;
                  -webkit-box-align: center;
                  align-items: center;
                  -ms-flex-pack: start;
                  -webkit-box-pack: start;
                  justify-content: flex-start;
                  margin-bottom: 10px;

                  .successTips {
                    width: 14px;
                  }

                  strong {
                    color: rgb(224, 19, 19);
                    font-size: 14px;
                    margin: 0 6px;
                  }

                  span {
                    font-size: 14px;
                    color: #636363;
                  }
                }
              }
            }

            .error {
              border: 1px solid #f0c6c6;
              background-color: #fff6f6;
            }

            .success {
              border: 1px solid #acf8d7;
              background-color: #dcece5;

              .main .main_title strong {
                color: #00a15b;
              }
            }
          }
        }
      }
    }

    .verification {
      .banner_left {
        width: 416px;
        height: 100%;
        border: 8px solid #f6f6f6;
        border-radius: 5px;
        box-sizing: border-box;
        box-sizing: border-box;

        img {
          display: block;
          margin: 20px auto;
        }
      }

      .input_value {
        padding: 5px 0 0 40px;
        box-sizing: border-box;
        box-sizing: border-box;
        width: var(--valueWidth);

        h2 {
          height: 42px;
          font-family: PingFangSC;
          font-weight: 600;
          font-size: 28px;
          color: #000;
          letter-spacing: 0;
          margin-bottom: 1px;
          margin-top: 0;
        }

        p {
          margin-top: 0;
          height: 22px;
          font-family: PingFangSC;
          font-weight: 400;
          font-size: 16px;
          color: #999;
          letter-spacing: 0;
          margin-bottom: 13px;

          img {
            position: relative;
            top: 1px;
            left: 1px;
            margin-right: 3px;
          }
        }

        .step-p {
          margin: 20px 0;
        }

        .verification_title_bottom {
          width: 100%;
          height: 65px;
          position: relative;

          .t-input {
            display: inline-block;
            width: var(--inputWidth);
            height: 65px;

            input {
              width: 100%;
              height: 100%;
              outline: none;
              border: 1px solid #c1c1c1;
              border-radius: 4px;
              padding-left: 56px;
              box-sizing: border-box;
              box-sizing: border-box;
            }
          }

          .btn_sbmit {
            cursor: pointer;
            display: inline-block;
            width: 183px;
            height: 65px;
            background: #2468f2;
            box-shadow: 0 2px 8px 0 #b0c1e4;
            box-shadow: 0 2px 8px 0 #b0c1e4;
            border-radius: 4px;
            line-height: 65px;
            text-align: center;
            font-weight: 500;
            font-size: 18px;
            color: #fff;
            letter-spacing: 0;
            margin-left: 16px;
          }

          .btn_sbmit_disabled {
            background: #8e9299;
          }

          img {
            position: absolute;
            left: 18px;
            top: 23px;
            z-index: 99;
          }

          .loading-icon {
            width: 50px;
            position: relative;
          }
        }

        .ver_bottom {
          width: 100%;
          height: 88px;
          padding: 20px;
          box-sizing: border-box;
          box-sizing: border-box;
          background: rgba(247, 250, 255, .73);
          border: 1px solid #dde3ec;
          display: flexbox;
          display: box;
          display: flex;
          -ms-flex-align: center;
          -webkit-box-align: center;
          align-items: center;
          -ms-flex-pack: justify;
          -webkit-box-pack: justify;
          justify-content: space-between;

          .ver_bottom_step {
            display: flexbox;
            display: box;
            display: flex;
            -ms-flex-align: center;
            -webkit-box-align: center;
            align-items: center;
            -ms-flex-pack: start;
            -webkit-box-pack: start;
            justify-content: flex-start;

            & > span {
              display: inline-block;
              width: 10.4166666667vw;
              font-weight: 400;
              font-size: 14px;
              color: #666;
              margin-right: 6px;
            }

            .down {
              display: flexbox;
              display: box;
              display: flex;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              -ms-flex-pack: center;
              -webkit-box-pack: center;
              justify-content: center;
              -ms-flex-negative: 0;
              flex-shrink: 0;

              & > img {
                width: 13px;
              }

              & > a {
                font-size: 15px;
                color: #2468f2;
                text-decoration: underline;
                letter-spacing: 0;
                font-weight: 600;
                font-family: PingFangSC-Medium;
                margin-left: 4px;
              }
            }
          }

          .ver_bottom_img {
            width: 34px;

            img {
              width: 100%;
            }
          }
        }

        span:last-child {
          font-size: 13px;
        }

        span {
          color: #333;
        }
      }
    }
  }
}

.hash-unpass-icon {
  width: 63px;
}

.error-modal-body {
  text-align: center;

  .tea-text {
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
  }

  .tea-text-danger,
  .tea-text-strong {
    font-size: 16px;
  }

  .tea-form__text {
    text-align: left;
  }

  .hash-loading-icon {
    width: 300px;
  }
}

.tea-dialog__inner .tea-btn {
  color: #666;
}

.error-modal-body {
  .upload_file_main {
    width: 100%;
    box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    overflow-y: auto;
    background-color: #fff;
    position: relative;

    .FileSec,
    .FileSec > .FileSec_left {
      display: flexbox;
      display: box;
      display: flex;
      -ms-flex-align: center;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
    }

    .FileSec {
      border-bottom: 1px solid #d9d9d9;
      height: 3.125vw;
      width: 100%;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;

      .FileSec_left {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        margin-left: 10px;

        img {
          width: 20px;
          margin-right: 10px;
        }

        p {
          font-size: 14px;
          margin-bottom: 0;
          color: #333;
          margin-left: 10px;
        }
      }

      .FileSec_right {
        display: flexbox;
        display: box;
        display: flex;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;

        img {
          width: 13px;
        }

        .close-icon {
          margin-right: 15px;
          cursor: pointer;
        }

        .FileSec_right_tab {
          width: 68px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border: 1px solid #237dd5;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang;
          color: #237dd5;
          margin-right: 20px;
        }
      }
    }

    .error,
    .success {
      margin-bottom: 10px;

      .FileSec {
        border: 0;
      }

      .unpass-tag,
      .pass-tag {
        position: relative;

        p {
          font-size: 14px;
          padding: 0 5px;
          margin-right: 30px;
          margin-top: 13px;
        }

        .open_icon,
        .close_icon {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 20px;
          width: 10px;
        }

        .close_icon {
          transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      .unpass-tag {
        p {
          color: rgb(224, 19, 19);
          border: 1px solid rgb(224, 19, 19);
        }
      }

      .pass-tag {
        p {
          color: #00a15b;
          border: 1px solid #00a15b;
        }
      }

      .main {
        padding: 0 10px;

        .main_title {
          display: flexbox;
          display: box;
          display: flex;
          -ms-flex-align: center;
          -webkit-box-align: center;
          align-items: center;
          -ms-flex-pack: start;
          -webkit-box-pack: start;
          justify-content: flex-start;
          margin-bottom: 10px;

          .successTips {
            width: 14px;
          }

          strong {
            color: rgb(224, 19, 19);
            font-size: 14px;
            margin: 0 6px;
          }

          span {
            font-size: 14px;
            color: #636363;
          }
        }
      }
    }

    .error {
      border: 1px solid #f0c6c6;
      background-color: #fff6f6;
    }

    .success {
      border: 1px solid #acf8d7;
      background-color: #dcece5;

      .main .main_title strong {
        color: #00a15b;
      }
    }
  }
}

.bg-modal .tea-dialog__inner {
  background-image: linear-gradient(180deg, #dae5fd, #fff);
  background-size: 100% 150px;
  background-repeat: no-repeat;
  border-radius: 10px;

  .tea-form__item {
    .tea-form__label {
      color: #000;
    }
  }
}

.bg-modal-success .tea-dialog__inner {
  width: 600px;
}
