.kvtx_data {
  .kvtx_data_i {
    display: flex;
    padding: 8px;
  }

  .kvtx_data_l {
    flex: 1
  }

  .kvtx_data_v {
    flex: 7;
    word-break: break-word;
    white-space: break-spaces;
  }

  .kvtx_data_v_b {
    background-color: #eee;
    padding: 20px;
    max-height: 500px;
    display: inline-block;
    overflow-y: auto;
  }
}