.common-table {
  max-width: 1600px;
  margin: 0 auto;
  .t-table {
    margin-top:20px;
    box-shadow: 0 0 4px 0 rgb(104 104 104 / 50%);
  }
}
.table-pagination{
  padding:14px 24px;
}
.table-top{
  display: flex;
}
.table-top>h3{
  width:300px;
}
.table-form{
  flex:1;
  padding-top:14px;
}