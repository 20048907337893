body {
  min-width: 1200px;
}
.t-layout {
  min-height: 100vh;
  background-color: rgb(235, 237, 241);
}
.t-layout--content {
  padding: 0 20px;
}
.home-search {
  &__box {
    padding: 84px 0 120px 0;
    .search-media {
      margin: 0 auto;
      width: 800px;
      &__input {
        input {
          height: 44px;
          border-color: #0052d9;
          border-width: 2px;
        }
      }
      &__btn {
        height: 44px;
        line-height: 44px;
      }
    }
  }
  &__logo {
    width: 360px;
    height: 94px;
    margin: 0 auto 24px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 4px;
  }
}
.home-media {
  margin: 16px auto;
  max-width: 1200px;
}
.t-tooltip {
  max-width: 600px !important;
}
@media screen and (max-height: 1000px) {
  .home-search {
    &__box {
      padding-top: 4.7vh;
      padding-bottom: 7.5vh;
    }
    &__logo {
      height: 7vh;
      text-align: center;
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-height: 780px) {
  .home-search {
    &__box {
      padding-top: 32px;
      padding-bottom: 50px;
    }
    &__logo {
      height: 108px;
    }
  }
}