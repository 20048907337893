.metrics-board {
  &__value-container {
    margin-top: 22.5px;
  }

  &__value {
    font-size: 28px;
  }

  &__unit {
    margin-left: 4px;
  }
}
