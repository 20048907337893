.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Detail_title {
  max-width: 1600px;
  margin: 1em auto;
  text-align: left;
  font-size: 20px;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mt0 {
  margin-top: 0;
}

.Addr-Tabs__container {
  max-width: 1600px;
  margin: 20px auto;
  box-shadow: 0 0 4px 0 rgb(104 104 104 / 50%);
}

.tx-tag {
  width: 44px;
  display: inline-block;
  text-align: center;
}

.z-center {
  text-align: center;
}

.z-underline {
  text-decoration: underline;
}

.t-layout--footer a {
  color: rgba(0, 0, 0, .4);
  text-decoration: none;
}

.t-popup-content.t-tooltip {
  /* stylelint-disable-next-line declaration-no-important */
  word-break: break-all !important;
}

.tr-status {
  position: relative;
}

.tr-status-icon {
  position: absolute;
  top: 3px;
  left: -20px;
  color: red;
}

.mt20 {
  margin-top: 20px;
}

.text-content {
  padding: 20px;
  border: 1px solid #ccc;
  word-break: break-word;
  white-space: break-spaces;
}