.t-card {
  max-width: 1600px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(104, 104, 104, 0.5);

  & + .t-card {
    margin-top: 16px;
  }

  &--full {
    max-width: 100%;
  }

  &__header,
  &__body,
  &__footer {
    padding: 24px;
  }

  &__header {
    box-sizing: border-box;
    border-bottom: solid 1px #eeeeee;
    h3 {
      padding: 0;
      margin: 0 10px 0 0;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.9);
      text-align: left;
      line-height: 34px;
    }
  }

  &__justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &-col {
      &--left {
        h3 {
          color: rgba(0, 0, 0, 0.9);
          margin: 0 8px 0 0;
          padding: 0;
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
        }
        span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
