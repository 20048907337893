.search-media {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;

  &__input {
    position: relative;
    flex: 1;

    input {
      width: 100%;
      height: 40px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border: 1px solid #dcdcdc;
      border-right: none;
      padding-left: 53px;
      padding-right: 30px;
      font-size: 16px;
      box-sizing: border-box;
      color: #000000;
      outline: 0;
      &:focus,
      &:hover {
        border-color: #0052d9;
      }
      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    &--search,
    &--close {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }

    &--search {
      width: 20px;
      height: 20px;
      left: 20px;
      background-image: url(../../../assets/img/icon-search.svg);
    }
    &--close {
      width: 16px;
      height: 16px;
      right: 10px;
      background-image: url(../../../assets/img/icon-close-16.svg);
    }
  }

  &__btn {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    width: 68px;
    height: 40px;
    line-height: 40px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #ffffff;
    background-color: #0052d9;
  }
}
