.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 0;
  padding: 0 30px;
  height: 64px;
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  &__logo {
    width: 90px;
    height: 48px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__search {
    flex: 1;
    .search-media {
      margin: 0 auto;
    }
  }
  &__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      .header-nav__subnav {
        display: block;
      }
    }

    &.has-subnav {
      & > .title::after {
        display: inline-block;
      }
    }
    & > .title {
      display: block;
      cursor: pointer;
      width: 104px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      border-radius: 3px;
      text-align: center;
      &:hover {
        color: #000;
        background-color: #eee;
        &::after {
          transform: rotate(-180deg);
        }
      }
      &::after {
        content: '';
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 4px;
        width: 16px;
        height: 16px;
        background-image: url(../../../assets/img/icon-arrow.svg);
      }
    }
  }
  &__subnav {
    display: none;
    position: absolute;
    z-index: 99;
    width: 180px;
    right: 0px;
    top: 100%;
    margin-top: 4px;
    border-radius: 3px;
    background-color: #2c2c2c;
    &:before {
      content: '';
      position: absolute;
      z-index: 99;
      left: 0;
      right: 0;
      top: -4px;
      height: 4px;
      background-color: transparent;
    }
    &.is-acive {
      display: block;
    }
    ol {
      padding: 0;
      margin: 0;
      overflow: hidden;
      border-radius: 3px;
      li {
        box-sizing: border-box;
        cursor: pointer;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .icon,
        span {
          display: inline-block;
          vertical-align: middle;
        }
        &:hover {
          background: #4b4b4b;
        }
      }
    }
  }
}
